import UrlApi from "../../utils/Url";
import { GetFunction } from "../../utils/axios_connection";
export const GetTeam =
    async ({ callback }) => {
        callback(false)
        try {

            let link = `${UrlApi.ourteam.get}`;
            const response = await GetFunction({
                data: {},
                Route: link,
                showalert: false,
                isMultipart: false,
            });
            callback(response);
        } catch (err) {
            callback(false);
        }
    };

export const GetTeamById =
    async ({ callback ,id}) => {
        callback(false)
        try {

            let link = `${UrlApi.ourteam.getbyid}/${id}`;
            const response = await GetFunction({
                data: {},
                Route: link,
                showalert: false,
                isMultipart: false,
            });
            callback(response);
        } catch (err) {
            callback(false);
        }
    };
    
      export const GetTestimonials =
      async ({ callback }) => {
          callback(false)
          try {
  
              let link = `${UrlApi.testimonials.get}`;
              const response = await GetFunction({
                  data: {},
                  Route: link,
                  showalert: false,
                  isMultipart: false,
              });
              callback(response);
          } catch (err) {
              callback(false);
          }
      };
  