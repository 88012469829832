import { CheckDataIfExit } from "../../helper/publicFunction";
import UrlApi from "../../utils/Url";
import { GetFunction, PostFunction } from "../../utils/axios_connection";



export const GetServices =
  async ({ callback }) => {
    callback(false)
    try {

      let link = `${UrlApi.services.get}`;
      const response = await GetFunction({
        data: {},
        Route: link,
        showalert: false,
        isMultipart: false,
      });
      callback(response);
    } catch (err) {
      callback(false);
    }
  };
  

export const GetSlide =
  async ({ callback }) => {
    callback({data:[]})
    try {

      let link = `${UrlApi.sliderShow.get}`;
      const response = await GetFunction({
        data: {},
        Route: link,
        showalert: false,
        isMultipart: false,
      });
      callback(response);
    } catch (err) {
      callback({data:[]});
    }
  };



export const SendEmail =
async  ({ callback, values }) =>
    {
      try {
        var bodyFormData = {};
        if (CheckDataIfExit(values?.name)) {
          bodyFormData.name = values?.name?.trim();
        }
        if (CheckDataIfExit(values?.phone)) {
          bodyFormData.phone = values?.phone
        }
        if (CheckDataIfExit(values?.email)) {
          bodyFormData.email = values?.email?.trim()
        }
        if (CheckDataIfExit(values?.message)) {
          bodyFormData.message = values?.message?.trim()
        }

        const response = await PostFunction({
          data: bodyFormData,
          Route: UrlApi.SendEmail.sendemail,
          showalert: true,
          isMultipart: false,
        });

        callback(response);
      } catch (err) {

        callback(false);
      }
    };


