import React from 'react';
import SettingsCard from '../components/SettingsCard';
import FormContainer from '../containers/FormContainer';
import { useState, useEffect } from 'react';
import { GetServices } from '../Pages/HomePages/actions';
import TeamContainer02 from './TeamContainers/TeamContainer02';
import { GetSettings } from './AboutContainers/actions';
const Settings = () => {
    const [data, setData] = useState([]);


    const GetData = () => {
        GetSettings({
            callback: (data) => {
              
                setData(data?.data);
            },
        })
    };

    useEffect(() => {
        window.scroll(0, 0)
        GetData();
    }, []);

    return (
        <main className="wrapper">
            <section className="services inner in-service pb-0">
                <div className="container">
                    <div className="row">
                        <div className="settings_wrap" >

                            {data &&
                                <SettingsCard
                                    key={data.id}
                                    item={data}
                                />

                            }

                        </div>

                        {/* <TeamContainer02 /> */}


                    </div>
                </div>
            </section>

            {/* <Testimoals /> */}
            <FormContainer />

        </main>
    );
};

export default Settings;