import React from 'react';
import UrlApi from '../utils/Url';
const ServiceCard = ({item}) => {
    return (
        <div className="icon_box type-2 res_service">
            {/* <h6>{props.number}</h6> */}
            {/* <img src={props.imgDark} alt="Icon Box" className="dark" />
            <img src={props.imgLight} alt="Icon Box" className="light" /> */}
           <img src={UrlApi.baseUrlImage + item.image} alt="img" className=""  />
          
            <h4 className="text-white">{item.title}</h4>
            <p className="text-gray-600">{item.short_description}</p>
            {/* <div className="arrow_effect">
                <a href="/service-details"><span className="crossline1"></span><span className="crossline2"></span></a>
            </div> */}
            </div>
      

    );
};

export default ServiceCard;