import React, { useState } from 'react';
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { SendEmail } from '../Pages/HomePages/actions';
const Form = () => {
  


  const Schema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string().email().required("Email is required"),
    phone: Yup.string().required("phone is required"),
    message:Yup.string().required("message is required")
  });
  
  
    return (
      <Formik
      validationSchema={Schema}
      initialValues={{name:"", email: "", phone: "", message: "" }}
      onSubmit={(values,{ resetForm }) => {
        SendEmail({
          values,
          callback: (response) => {
            resetForm();
          },
        });
      }}
    >
      {({ handleChange, handleSubmit, values, errors }) => (
        <div className="home_contact">
            {/* <Form action="contact.php" method="POST"> */}
                <input className="form-control form-control-lg" name="name" id="name" type="text" placeholder="Your Name*" aria-label=".form-control-lg example"  value={values.name}
                onChange={handleChange("name")}  />
                <p style={{ color: 'red' }}>{errors.name}</p>
                <input className="form-control form-control-lg" name="phone" id="phone" type="number" placeholder="Your Phone No" aria-label=".form-control-lg example" value={values.phone}
                onChange={handleChange("phone")}   />
                 <p style={{ color: 'red' }}>{errors.phone}</p>
                <input className="form-control form-control-lg" name="email" id="email" type="email" placeholder="Your Email*" aria-label=".form-control-lg example" value={values.email}
                onChange={handleChange("email")}  />
                 <p style={{ color: 'red' }}>{errors.email}</p>
                <textarea className="form-control pt-4" name="message" id="message" placeholder="Your Message" rows="3" value={values.message}
                onChange={handleChange("message")}   ></textarea>
                <p style={{ color: 'red' }}>{errors.message}</p>
                <div className="btn_group">
                    <button type="submit" className="btn olive" onClick={handleSubmit}>Send Mail</button>
                </div>
            {/* </Form> */}

        </div>

)}
</Formik>
  );
        
};

export default Form;
