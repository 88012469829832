export const RouterName={
home:{
    home:"/home",
},

// project3:"/project",
project:"/project",
byserviceid:"//project/:id",
detail:"/project-details/:id",
ourservices:"/ourservices",
teamdetails:"/team-details/:id",
team:"/team",
contact:"/contact",

};
