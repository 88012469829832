import React, { useEffect, useState } from 'react';

const FunFact02 = ({ item, start }) => {
    const [reachNumber,setReachNumber] = useState(1)
    useEffect(() => {
        if(start){
              for(let i=0;i<=item?.number;i++){
                setTimeout(() => {
                setReachNumber(i)
            }, 30+i*30);
            } 
        }
    }, [item?.number,start])

    return (
        <div className="funfact">
            <div className="funfact_content">
                <div className="d-flex align-items-center justify-content-center"><h2 className="fun-number">{reachNumber}</h2> <span>+</span></div>
                <p>{item.name}</p>
            </div>
        </div>
    );
};

export default FunFact02;