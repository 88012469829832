import { RouterProvider } from 'react-router-dom';
import 'react-select2-wrapper/css/select2.css';
import 'swiper/css';
import 'swiper/css/autoplay';
import "swiper/css/effect-coverflow";
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import './App.css';
import ThemeSwitcher from './Pages/Shared/ThemeSwitcher';
import { routes } from './routes/Router';
import './assets/css/style.css';
import { Helmet } from "react-helmet";
import { GetSettings } from './containers/AboutContainers/actions';
import { useState, useEffect } from 'react';
import UrlApi from './utils/Url';

function App() {
  const [data, setData] = useState([]);


const GetData = () => {
  GetSettings({
    callback: (data) => {
      setData(data?.data);
    },
  })
};

useEffect(() => {
  GetData();
}, []);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Artshoc</title>
        {data &&
          <meta name="description" content={data.meta_description} />
        }
        {data &&
          <meta name="keywords" content={data.meta_keywords} />
        }
        {data &&
                 <meta property="og:image" content={UrlApi.baseUrlImage + data.meta_image} />
                }
            </Helmet>
           
      <ThemeSwitcher/>
      <RouterProvider router={routes} /> 
      <a href="https://api.whatsapp.com/send?phone=96171717616" target="_blank" class="scroll-whatsapp">

      <div style={{display:'flex',backgroundColor:'#25D366',width:60,height:60,borderRadius:50,justifyContent:'center',alignItems:'center',position:'fixed',bottom:30,left:25,zIndex:100}}>
          <i class="bi bi-whatsapp" style={{color:'white',fontSize:30}}></i>
      </div>
      </a>
    </div>
  );
}

export default App;
