import React from 'react';
import Video from "../components/Video";
import FormContainer from "../containers/FormContainer";
import FunFacts from '../containers/FunFacts/FunFacts';
import MissionContainer from "../containers/MissionContainer";
import ServiceContainer01 from '../containers/ServiceContainer';
import TeamContainer02 from '../containers/TeamContainers/TeamContainer02';
import Testimonials from '../containers/Testimonials';
import TextAbout from './TextAbout';
import {GetSettings} from '../../src/containers/AboutContainers/actions';
import { useState,useEffect } from 'react';
import { Helmet } from 'react-helmet';
import ServiceCard from '../components/ServiceCard';
import Settings from '../containers/Settings';

const AboutUs = () => {
   
    const [data, setData] = useState([]);


    const GetData = () => {
        GetSettings({
            callback: (data) => {
          
                setData(data?.data);
            },
        })
    };

    useEffect(() => {
        GetData();
    }, []);
    return (
        
        <main className="wrapper">
     <div className="App">
      <Helmet>
        <title>Artshoc-AboutUs</title>
        <meta name="description" content="App Description" />
        <meta name="theme-color" content="#008f68" />
      </Helmet>
    </div>
{data && 
               <MissionContainer
               key={data.id}
               item={data}
           />
            }
             
             <Settings />
           
            <TeamContainer02 />
         
        </main>
    );
};

export default AboutUs;