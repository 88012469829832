import React from 'react';
import ServiceCard03 from '../components/ServiceCard03';
import { useEffect } from "react";
import { useState } from "react";
import { GetServices } from '../Pages/HomePages/actions';
import { Link } from 'react-router-dom';
const ServiceContainer04 = () => {
    // const ServiceData03 = [
    //     {
    //         id: '01',
    //         img01: "images/icon_box/1.png",
    //         title: "Urban Design",
    //         text: "Mrittik Architects is a full-service design firm providing architecture, master planning, urban design, interior architecture."
    //     },
    //     {
    //         id: '02',
    //         img01: "images/icon_box/2.png",
    //         title: "Interior Design",
    //         text: "Mrittik Architects is a full-service design firm providing architecture, master planning, urban design, interior architecture."
    //     },
    //     {
    //         id: '03',
    //         img01: "images/icon_box/3.png",
    //         title: "Landscape Design",
    //         text: "Mrittik Architects is a full-service design firm providing architecture, master planning, urban design, interior architecture."
    //     },
    //     {
    //         id: '04',
    //         img01: "images/icon_box/4.png",
    //         title: "Industrial Design",
    //         text: "Mrittik Architects is a full-service design firm providing architecture, master planning, urban design, interior architecture."
    //     },
    //     {
    //         id: '05',
    //         img01: "images/icon_box/5.png",
    //         title: "3D Modeling",
    //         text: "Mrittik Architects is a full-service design firm providing architecture, master planning, urban design, interior architecture."
    //     },
    //     {
    //         id: '06',
    //         img01: "images/icon_box/6.png",
    //         title: "Blueprint Design",
    //         text: "Mrittik Architects is a full-service design firm providing architecture, master planning, urban design, interior architecture."
    //     }
    // ]
    const [data, setData] = useState([]);
  
  
    const GetData = () => {
        GetServices({
            callback: (data) => {
          
              setData(data?.data);
            },
          })
    };
  
    useEffect(() => {
      GetData();
    }, []);

    return (



        
        <main className="wrapper">
            <section className="services inner in-service pb-0" >
                <div className="container">
                    <div className="row">

                        {
                            data?.map(item =>
                                <ServiceCard03
                                    key={item.id}
                                    item={item}
                                />)
                        }

                    </div>
                    
                </div>
                <div className="centered-button">
                <div>
                    
      <Link to="/ourservices" className="btn btn-primary btn-about">
        Services
      </Link>
      {/* <button onClick={goToServiceDetails} className="btn btn-primary btn-services">
      Services
    </button> */}
    {/* <button onClick={() => window.location.href = '/about'} className="btn btn-primary btn-about">About Us</button> */}
    </div>
            </div>
                
            </section>


        </main>
    );
};

export default ServiceContainer04;