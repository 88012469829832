import $ from 'jquery';
import React, { useEffect, useState } from 'react';
import logoLight from "../../../assets/images/logo-light.png"
import logoDark from "../../../assets/images/logo-dark.png"
import { Link,useLocation } from 'react-router-dom';
const UpperHeader = () => {
    // Hide header on scroll down
    window.onscroll = function () { myFunction() }; function myFunction() {
        if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
            document.querySelector('header .container').classList.add('.top-up');
        } else {
            document.querySelector('header .container').classList.remove('.top-up');
        }
    }

    const [isActive, setActive] = useState("false");
    const handleToggle = () => {
        setActive(!isActive);
    };

    useEffect(() => {
        // Aside info bar
        $('.aside_open').click(function (e) {
            e.preventDefault();
            $('.aside_info_wrapper').addClass('show');
        });
        $('.aside_close').click(function (e) {
            e.preventDefault();
            $('.aside_info_wrapper').removeClass('show');
        });

        // Toggle Header Search
        $('.header_search .form-control-submit').click(function (e) {
            $('.open_search').toggleClass('active');
        });

        // Sticky Header
        var header = $("header");
        $(window).scroll(function () {
            var scroll = $(window).scrollTop();

            if (scroll >= 50) {
                header.addClass("bg-dark sticky");
            } else {
                header.removeClass("bg-dark sticky");
            }
        });
    }, []);
    const location = useLocation();
    function AutoClose() {
        document.body.className = "bg-dark";
    }
    
    return (
        
        <header className="header">
            <div className="container">
                <div className="header_inner d-flex align-items-center justify-content-between">
                    <div className="logo">
                        <Link to='/' clLinkssName="light_logo"><img src={logoLight} alt="logo" /></Link>
                        <Link to='/' className="dark_logo"><img src={logoDark} alt="logo" /></Link>
                    </div>


                    <div className="mainnav d-none d-lg-block">
            <ul className="main_menu">
                <li  onClick={AutoClose}
                     className={`menu-item ${location.pathname === '/' ? 'active' : ''}`}>
                    <Link to="/">Home</Link>
                </li>
                <li  onClick={AutoClose} className={`menu-item ${location.pathname === '/ourservices' ? 'active' : ''}`}>
                    <Link to="/ourservices">Our Services</Link>
                </li>
                <li  onClick={AutoClose}  className={`menu-item ${location.pathname === '/about' ? 'active' : ''}`}>
                    <Link to="/about">About Us</Link>
                </li>
                <li  onClick={AutoClose} className={`menu-item ${location.pathname === '/project' ? 'active' : ''}`}>
                    <Link to="/project">Projects</Link>
                </li>
                <li  onClick={AutoClose} className={`menu-item ${location.pathname === '/contact' ? 'active' : ''}`}>
                    <Link to="/contact">Contact Us</Link>
                </li>
            </ul>
        </div>
               
                    <div className="header_right_part d-flex align-items-center">
                        <button className="aside_open asidefooter">
                            <span className="line"></span>
                            <span className="line"></span>
                            <span className="line"></span>
                        </button>
                        <button type="button" className="mr_menu_toggle d-lg-none">
                            <i className="bi bi-list"></i>
                        </button>

                    
                    </div>
                </div>
            </div>
        </header>
    );
};

export default UpperHeader;