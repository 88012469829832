import React from 'react';
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import SectionGridLines from '../components/SectionGridLines';
import TestimonialSlide from '../components/TestimonialSlide';
import { GetTestimonials } from './TeamContainers/actions';
import { useState,useEffect } from 'react';
const Testimonials = () => {
    const [data, setData] = useState([]);
  
  
    const GetData = () => {
        GetTestimonials({
            callback: (data) => {
              
              setData(data?.data);
            },
          })
    };
  
    useEffect(() => {
      GetData();
    }, []);
    return (
        data?.length > 0 ?<section className="testimonial box_padding pb-0">
            <SectionGridLines />
            <div className="has_line_lg"></div>

            <div className="testimonial_inner bg-black">
                <div className="swiper swiper_testimonial">
                <Swiper
                        modules={[Pagination]}
                        autoplay={{
                            delay: 5000,
                            disableOnInteraction: true,
                            speed: 5000
                        }}
                        slidesPerView={1.5}
                        loop={true}
                        spaceBetween={150}
                        centeredSlides={true}
                        pagination={{
                            clickable: true,
                            dynamicBullets: true,
                        }}
                    >
                        
               
                            {
                                data?.map(item=>  
                                       <SwiperSlide
                                       key={item.id}>
                                    <TestimonialSlide 
                                    
                                    item={item}

                                    />  
                                    </SwiperSlide>
                                    )
                          
                          }
                      
                    
                    </Swiper>
                </div>
            </div>
        </section>
        :null
    );
};

export default Testimonials;