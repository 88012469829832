import React from 'react';
import { EffectFade, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react";
import PageHeder from '../../components/PageHeder';
import Paragraph from '../../components/Paragraph';
import ProjectCard09 from '../../components/ProjectElements/ProjectCard09';
import SectionGridLines from '../../components/SectionGridLines';
import SectionHeader from '../../components/SectionHeader';
import { GetGalleryById } from './actions';

import { useState, useEffect } from 'react';

const ProjectGallery09 = ({ project_id }) => {

    const [data, setData] = useState([]);


    const GetData = () => {
        GetGalleryById({
            project_id: project_id,
            callback: (data) => {
                setData(data?.data);
            },
        })
    };

    useEffect(() => {
        GetData();
    }, []);


    return (
        <section className="projects packery">
            <SectionGridLines />
            <div className="large_font">
                <SectionHeader title="Gallery" />
            </div>
            <div className="container">
                {/* <div className="section-header text-center has_line">
                    <PageHeder className='text-white' title="Project Gallery" />
                
                </div> */}
    

                <div className='wrapping-icon-box'>
              
                    {data?.map(item =>
                        <ProjectCard09
                            key={item.id}
                            item={item}
                        />
                    )}


                </div>




            </div>
        </section>
    );
};

export default ProjectGallery09;