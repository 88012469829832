import React, { useState, useEffect } from 'react';
import { EffectFade, Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react";
import TeamCard02 from '../../components/TeamElements/TeamCard02';
import { GetTeam } from './actions';

const TeamContainer02 = () => {
    const [data, setData] = useState([]);

    const GetData = () => {
        GetTeam({
            callback: (data) => {
                setData(data?.data);
            },
        })
    };

    useEffect(() => {
        window.scroll(0, 0);
        GetData();
        
    }, []);

    const isMobile = window.innerWidth < 430;
    const showArrows = isMobile || (data?.length >= 3);

    return (
        <section>
            <div className="container">
                <div className="section-header text-center has_line">
                    <h1 className="text-white">Our Team</h1>
                </div>

                <div className="team_inner">
                    <div className="swiper swiper_team">

                        <Swiper
                            modules={[Navigation, EffectFade]}
                            effect="slide"
                            breakpoints={{
                                640: {
                                    width: 640,
                                    slidesPerView: 1,
                                },
                                768: {
                                    width: 768,
                                    slidesPerView: 2,
                                },
                                992: {
                                    width: 920,
                                    slidesPerView: 2,
                                },
                                600: {
                                    slidesPerView: 2,
                                },
                                992: {
                                    slidesPerView: 3,
                                },
                            }}
                            spaceBetween={30}
                            loop={true}
                            autoplay={true}
                            navigation={showArrows && {
                                prevEl: '.swiper-button-prev',
                                nextEl: '.swiper-button-next',
                            }}
                        >
                            {data?.map(item =>
                                <SwiperSlide key={item.id}>
                                    <TeamCard02
                                        item={item}
                                    />
                                </SwiperSlide>
                            )}
                        </Swiper>

                        {/* <!-- Add Buttons --> */}
                        {showArrows && (
                            <div className="swiper-navigation">
                                <div className="swiper-button-prev"><i className="bi bi-arrow-left"></i></div>
                                <div className="swiper-button-next"><i className="bi bi-arrow-right"></i></div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default TeamContainer02;
