import { createBrowserRouter } from "react-router-dom";
import HomeLayout from "../Layouts/HomeLayout";
import HomeLayout02 from "../Layouts/HomeLayout02";
import HomeLayout03 from "../Layouts/HomeLayout03";
import HomeLayout04 from "../Layouts/HomeLayout04";
import ShopLayout from "../Layouts/ShopLayout";
import AboutUs from "../Pages/AboutUs";
import Blog from "../Pages/BlogPages/Blog";
import BlogDetails from "../Pages/BlogPages/BlogDetails";
import CommingSoon from "../Pages/CommingSoon";

import ErrorPage from "../Pages/ErrorPage";
import Home01 from "../Pages/HomePages/Home01";
import Home02 from "../Pages/HomePages/Home02";
import Home03 from "../Pages/HomePages/Home03";
import Home04 from "../Pages/HomePages/Home04";
import Home05 from "../Pages/HomePages/Home05";
import Home06 from "../Pages/HomePages/Home06";
import Home07 from "../Pages/HomePages/Home07";
import Home08 from "../Pages/HomePages/Home08";
import Home10 from "../Pages/HomePages/Home10";
import Login from "../Pages/Login";
// import Project02 from "../Pages/ProjectPages/Project02";
// import Service02 from "../Pages/ServicePages/Service02";
import ServiceDetails from "../Pages/ServicePages/ServiceDetails";
import Shop01 from "../Pages/ShopPages/Shop01";
import Shop02 from "../Pages/ShopPages/Shop02";
import Shop03 from "../Pages/ShopPages/Shop03";
import ShopCart from "../Pages/ShopPages/ShopCart";
import ShopCheckout from "../Pages/ShopPages/ShopCheckout";
import ShopProduct from "../Pages/ShopPages/ShopProduct";
import ThankYou from "../Pages/ThankYou";
import { productsAndCartData } from "../components/forJSON/getCart&ProductsData";
import React, { lazy } from "react";
import { Navigate } from "react-router-dom";
import Loadable from "../Layouts/loadable/Loadble";
import { RouterName } from "./RouterName";


const Home09 = Loadable(lazy(() => import("../Pages/HomePages/Home09")));
// const Project03= Loadable(lazy(() => import("../Pages/ProjectPages/Project03")));
const Project02= Loadable(lazy(() => import("../Pages/ProjectPages/Project02")));
const ProjectService= Loadable(lazy(() => import("../Pages/ProjectPages/Project02")));
const OurServices= Loadable(lazy(() => import("../Pages/ServicePages/Service01")));
const Contact= Loadable(lazy(() => import("../Pages/Contact")));
const ProjectDetails02= Loadable(lazy(() => import("../Pages/ProjectPages/ProjectDetails02")));

const TeamDetails=Loadable(lazy(() => import("../Pages/TeamPages/TeamDetails")));
const Team=Loadable(lazy(() => import("../Pages/TeamPages/Team")));

export const routes = createBrowserRouter([
    {
        path: "/",
        element: <HomeLayout />,
        children: [
            {
                path: "/",
                exact: true,
                element: (
                    <Navigate
                        to={
                            RouterName.home.home
                        }
                    />
                ),
            },
            {
                path: RouterName.home.home,
                element: <Home09 />,
            },
      
            {
                path:RouterName.project,
                exact:true,
                element: <Project02/>,
            },
            {
                path:RouterName.byserviceid,
                exact:true,
                element: <ProjectService/>,
            },
            {
                path:RouterName.detail,
                extract:true,
                element: <ProjectDetails02 />,
            },
            // {
            //     path:RouterName.detail,
            //     extract:true,
            //     element: <ProjectDetails02 />,
            // },
            // {
            //     path: '/project-details-2',
            //     element: <ProjectDetails02 />,
            // },
            {
                path: RouterName.ourservices,
                element: <OurServices />,
            },
            // {
            //     path: '/service-2',
            //     element: <Service02 />,
            // },
            // {
            //     path: RouterName.serviceDetail,
            //     element: <ServiceDetails />,
            // },
            {
                path: '/login',
                element: <Login />,
            },
            {
                path: '/coming-soon',
                element: <CommingSoon />,
            },
            {
                path: '/thank-you',
                element: <ThankYou />,
            },
            {
                path:RouterName.team,
                element: <Team />,
            },
            {
                path: RouterName.teamdetails,
                element: <TeamDetails />,
            },
            {
                path: RouterName.contact,
                element: <Contact />,
            },
            {
                path: '/about',
                element: <AboutUs />,
            },
            {
                path: '/blog',
                element: <Blog />,
            },
            {
                path: '/blog-details',
                element: <BlogDetails />,
            },
            {
                path: '*',
                element: <ErrorPage />,
            },

        ]
    },
    {
        path: "/",
        element: <HomeLayout02 />,
        children: [
            {
                path: '/home-2',
                element: <Home02 />,
            },

        ]
    },
    {
        path: "/",
        element: <HomeLayout03 />,
        children: [
            {
                path: '/home-7',
                element: <Home07 />,
            },

        ]
    },
    {
        path: "/",
        element: <HomeLayout04 />,
        children: [
            {
                path: '/home-10',
                element: <Home10 />,
            },

        ]
    },
    {
        path: "/",
        loader: productsAndCartData,
        element: <ShopLayout />,
        children: [
            {
                path: '/shop-1',
                element: <Shop01 />,
            },
            {
                path: '/shop-2',
                element: <Shop02 />,
            },
            {
                path: '/shop-3',
                element: <Shop03 />,
            },
            {
                path: '/product-details',
                element: <ShopProduct />,
            },
            {
                path: '/shop-cart',
                element: <ShopCart />,
            },
            {
                path: '/shop-checkout',
                element: <ShopCheckout />,
            },
            // {
            //     path: '/shop-product/:id',
            //     element: <SelectedProduct/>,
            //     loader: ({ params }) => fetch(`https://mrittik-server.vercel.app/products/${params.id}`)
            // },
            // {
            //     path: '/shop-category/:id',
            //     element: <ShopCategory/>,
            //     loader: ({ params }) => fetch(`https://mrittik-server.vercel.app/products/category/${params.id}`),
            // },

        ]
    }
])