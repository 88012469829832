import React from 'react';
import UrlApi from '../../utils/Url';
const ProjectCard09 = ({ item }) => {


    return (


        // <div className="col-lg-12" style={{ backgroundColor: 'red' }}>
        //     <div className="col-lg-6">
        //         <div class="img_box" style={{flex:1,backgroundColor:'blue',padding:100}}>
        //             <div className={`img_box_inner${!item.image_after ? ' center_image' : ''}`}>
        //                 <img src={UrlApi.baseUrlImage + item.image_before} alt="Icon Box" />
        //                 {item.image_after && <img src={UrlApi.baseUrlImage + item.image_after} alt="Icon Box" />}
        //             </div>
        //         </div>
        //     </div >
        // </div>

        <div   style={{ display: 'flex', columnGap: '5%', flexWrap: 'wrap' }}>
            <div  style={{ flex: 1, justifyContent: !item.image_after && 'center', display: !item.image_after && 'flex' }}>
                <img  src={UrlApi.baseUrlImage + item.image_before} alt="Icon Box" />
            </div>
            {item.image_after && <div style={{ flex: 1 }}>
                <img  src={UrlApi.baseUrlImage + item.image_after} alt="Icon Box" />
            </div>}
        </div>

    );
};

export default ProjectCard09;