import React from 'react';
import UrlApi from '../../utils/Url';
import { Link } from 'react-router-dom';
const TeamCard02 = ({ item }) => {
    

    return (
        <div className="team-block" style={{zIndex:99999}}>
            <Link  to={`/team-details/${item?.id}`}>
            <img src={UrlApi.baseUrlImage + item.image} alt="img" />
            </Link>
            <h5 className="text-white"><Link  to={`/team-details/${item?.id}`}>{item.name}</Link></h5>
           
            <h6 className="text-uppercase text-olive">{item.position}</h6>
        </div>

        
    );
};

export default TeamCard02;