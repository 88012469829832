import React from 'react';
import { useEffect } from "react";
import { useState } from "react";
import { GetSettings } from './actions';
import UrlApi from '../../utils/Url';

const AboutContainer = () => {
    const [data, setData] = useState([]);

    const GetData = () => {
        GetSettings({
            callback: (data) => {
             
                setData(data?.data);
            },
        })
    };

    useEffect(() => {
        window.scroll(0,0)
        GetData();
    }, []);

    return (
        <section className="about style_two">
            <div className="large_font">
                <h2 className="floating_element text-dark-200 d-flex justify-content-center">About</h2>
            </div>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-5 col-md-5 col-sm-6">
                        <div className="about_image detail-image-container">
                            {data &&
                                <img src={UrlApi.baseUrlImage + data.about_image} alt="About" className="abt_img_1 detail-image enlarge"  />
                            }
                            <img src="images/about/e1.svg" alt="About" className="line_frame_1" />
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-7 col-sm-6">
                        <div className="about_text_inner">
                            <h2 className="text-dark-400">ABOUT </h2>
                            <div className="about_text mt-5">
                                {data &&
                                    <div dangerouslySetInnerHTML={{ __html: data.about_text }} />
                                }
                                {/* <button onClick={() => window.location.href = '/about'} className="btn btn-primary btn-about">About Us</button> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AboutContainer;
