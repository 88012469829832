
import React from 'react';

const TestimonialSlide = ({ item }) => {
 
    return (
        <div className="testimonial testimonial-block ">
            {/* <div className="top-left">
                <h6 className="text-olive">{item.client_name}</h6>/
                <h6>{item.position}</h6>
            </div>
            <p className=" text-center">{item.text}</p>
            <div className="bottom-right">
                <h6 className="text-olive">{item.projects}</h6>
                <h6>{item.country}</h6>
                <h6>{item.date}</h6>
            </div> */}
                    <div className="testimonial-block text-center">
            <p>{item.text}</p>
            <h6 className="text-olive">—  {item.client_name}</h6>
        </div>
        </div>
    );
};

export default TestimonialSlide;
