import React from 'react';
import UrlApi from '../utils/Url';
const SettingsCard = ({ item }) => {
    return (
        <>
            <div className="icon_box type-2 res_settings">

                <h4 className="text-white">Our Vision</h4>
                <p dangerouslySetInnerHTML={{__html:item?.about_vision}}  className="text-gray-600"/> 
            </div>

            <div className="icon_box type-2 res_settings">

                <h4 className="text-white">Our Values</h4>
               
                <p dangerouslySetInnerHTML={{__html:item?.about_values}}  className="text-gray-600"/> 
            </div>
        </>
    );
};

export default SettingsCard;