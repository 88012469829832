import UrlApi from "../utils/Url";

const ServiceCard03 = ({ item }) => {
  
    return (
        <div className="col-lg-4" >
            <div class="icon_box">
                <div className="icon_box_inner">
                    <img src={UrlApi.baseUrlImage + item.image} alt="Icon Box" />
                    <h4 className="text-white">{item.title}</h4>
                    
                </div>
                
            </div>
        </div >

    );
};

export default ServiceCard03;