import UrlApi from "../../utils/Url";
import { GetFunction } from "../../utils/axios_connection";
export const GetGallery =
    async ({ callback }) => {
        callback(false)
        try {

            let link = `${UrlApi.Gallery.get}`;
            const response = await GetFunction({
                data: {},
                Route: link,
                showalert: false,
                isMultipart: false,
            });
            callback(response);
        } catch (err) {
            callback(false);
        }
    };
    export const GetGalleryById =
    async ({ callback ,project_id }) => {
        callback(false)
        try {

            let link = `${UrlApi.Gallery.getbyid}/${project_id}`;
            const response = await GetFunction({
                data: {},
                Route: link,
                showalert: false,
                isMultipart: false,
            });
            callback(response);
        } catch (err) {
            callback(false);
        }
    };