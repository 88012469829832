import React from 'react';
import UrlApi from '../../utils/Url';
import { Link } from 'react-router-dom';

const SliderCard08 = ({ item }) => {
  

    return (
   

        <div className="slider mystyleforslider" style={{ backgroundImage: `url(${UrlApi.baseUrlImage + item.image}) `}}>
      

            <div className="container">
                <div className="slide_content">
                    <div className="slide_content_wrapper mb-0 h-auto">
                        <div className="slide_content_inner">
                            <h4 className="text-white text-uppercase slidertext">{item.title}</h4>
                            <div className="details_link">
                                <Link to="/about">
                                    <span className="link_text slidertext2">{item.sub_title} - </span>
                                    <span className="link_icon slidertext2">Click Here</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SliderCard08;