import UrlApi from "../../utils/Url";
import { GetFunction } from "../../utils/axios_connection";



export const GetSettings =
    async ({ callback }) => {
        callback(false)
        try {

            let link = `${UrlApi.settings.get}`;
            const response = await GetFunction({
                data: {},
                Route: link,
                showalert: false,
                isMultipart: false,
            });
            callback(response);
        } catch (err) {
            callback(false);
        }
    };
    export const GetFunfacts =
    
    async ({callback}) => {
      callback(false)

      try {
        let link = `${UrlApi.funfacts.get}`;
        
        const response = await GetFunction({
          data: {},
          Route: link,
          showalert: false,
        });
        callback(response);
        // console.log({GEORGE:response})
      } catch (err) {
        // console.log({GEORGE:err})
        callback(false);
      }
    };
  
  