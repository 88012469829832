import UrlApi from "../utils/Url";

export let isDevelopmentMode =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development";

export const getLinkPagination = ({
  page = "",
  rowsPerPage = "",
  orderBy = "",
  typeOrder = "",
  search = "",
  fieldsSearch = "",
}) => {
  let quary;
  if (page != null && page != "null") {
    quary = `offset=${page}`;
  }
  if (rowsPerPage != null && rowsPerPage != "null") {
    quary = quary + `&page_size=${rowsPerPage}`;
  }
  if (orderBy && orderBy != "null") {
    quary = quary + `&sortField=${orderBy}`;
  }
  if (typeOrder && typeOrder != "null") {
    quary = quary + `&order=${typeOrder}`;
  }
  if (search && search != "null") {
    quary = quary + `&search=${search}`;
  }
  if (fieldsSearch && fieldsSearch != "null" && isValidArray(fieldsSearch)) {
    quary = quary + `&fieldsSearch=${JSON.stringify(fieldsSearch)}`;
  }
  return quary;
};

export const dataLocalStorage = {
  userinfo: "user",
  privileges: "privileges",
  loading_check_user: "loading_check_user",
};
export const columntype = {
  text: "text",
  image: "image",
  status: "status",
  date: "date",
  time: "time",
  action: "action",
  switch: "switch",
  checkbox: "checkbox",
  dateAndtime: "dateAndtime",
};

export const inputType = {
  title: "title",
  text: "text",
  number: "number",
  password: "password",
  phonenumber: "phonenumber",
  textarea: "textarea",
  email: "email",
  image: "image",
  dropdown: "dropdown",
  dropdownSearch: "dropdownSearch",
  multipleSelect: "multipleSelect",
  date: "date",
  time: "time",
  dateandtime: "dateandtime",
  action: "action",
  cancel: "cancel",
  checkbox: "checkbox",
  editor: "editor",
  map: "map",
  color: "color",
};
export const media_app = {
  slidershow: {
    name: "slidershow",
  },
  press_blog_news: {
    our_story: "our_story",
    impact: "impact",
  },
};

export const printData = ({ name, data, type }) => {
  if (isDevelopmentMode) {
    if (type == "error") {
      console.error({ [name]: data });
    } else {
      console.log({ [name]: data });
    }
  } else {
    return;
  }
};

export const saveLocalStorage = (key, data) => {
  localStorage.setItem([key], JSON.stringify(data));
};
export const saveSessionStorage = (key, data) => {
  sessionStorage.setItem([key], JSON.stringify(data));
};

export const getLocalStorage = (key) => {
  let data = localStorage.getItem(key);
  if (data) {
    return JSON.parse(data);
  } else {
    return "";
  }
};

export const removeLocalStorage = (key) => {
  localStorage.removeItem(key);
};

export const removeAllLocalStorage = () => {
  localStorage.clear();
};



export const CheckItemIfExit = (value, array, failedname = "id") => {
  let isExit = array?.some((element) => element[failedname] == value);
  return isExit;
};

export const removeItemFromArray = (id, array) => {
  let newArray = array?.filter((item) => item?.id !== id);
  return newArray;
};
export const getItemFromArray = (id, array) => {
  let newArray = array?.filter((item) => item?.id == id);
  return newArray[0];
};
export const AddItemToArray = (item, array) => {
  return [...array, item];
};
export const editItemInArray = (editedItem, array) => {
  const newArray = array.map((item) => {
    if (item.id === editedItem.id) {
      return { ...item, ...editedItem }; // Replace the item with the edited item
    }
    return item;
  });
  return newArray;
  // how can i used it
  //  let updatedArray = editItemInArray(
  //    {
  //      id: olditem.id,
  //      quantity: parseInt(olditem.quantity) + parseInt(item.quantity),
  //    },
  //    allCard
  //  );
};

export const ChackImageIsFile = ({ data, IsBoolean }) => {
  if (data instanceof Blob || data instanceof File) {
    const objectURL = URL.createObjectURL(data);
    if (IsBoolean) {
      return true;
    } else {
      return objectURL;
    }
  } else {
    if (IsBoolean) {
      return false;
    } else {
      return UrlApi.baseUrlImage + data;
    }
  }
};

export const CheckTypeFile = ({ name }) => {
  let type = name?.split(".")?.pop();
  if (
    type == "mp4" ||
    type == "mov" ||
    type == "webm" ||
    type == "avi" ||
    type == "wmv" ||
    type == "flv"
  ) {
    return "video";
  } else {
    return "image";
  }
};

export function isEquivalent(a, b) {
  try {
    return JSON.stringify(a) === JSON.stringify(b);
  } catch (e) {
    return false;
  }
}
export function isValidArray(array) {
  return array && Array.isArray(array) && array.length > 0;
}

export function validURL(str) {
  const regexp =
    /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
  if (regexp.test(str)) {
    return true;
  } else {
    return false;
  }
}

export function isNumber(data) {
  if (isNaN(data)) {
    return false;
  } else {
    return true;
  }
}
export const CheckDataIfExit = (data) => {
  if ((data != null && data != undefined) || data == 0) {
    return true;
  } else {
    return false;
  }
};

