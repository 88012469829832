import React from 'react';
import Paragraph from './Paragraph';
import SubHeading from './SubHeading';

const FormText = ({item}) => {
    return (
        <div className="col-lg-5">
            <div className="section-header">
                <SubHeading title="LET’S DISCUSS NEXT PROJECTS"></SubHeading>
                <div className="about_text mt-5">
                                {item &&
                                    <div dangerouslySetInnerHTML={{ __html: item.contact_text }} />
                                }
                           
                            </div>
            </div>
        </div>
    );
};

export default FormText;