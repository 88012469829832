 import UrlApi from "../../utils/Url";
import { GetFunction } from "../../utils/axios_connection";
export const GetProjectsByService =
    async ({ callback,serviceId  }) => {
        callback(false)
        try {

            let link = `${UrlApi.projects.getbyservice}/${serviceId}`;
            const response = await GetFunction({
                data: {},
                Route: link,
                showalert: false,
                isMultipart: false,
            });
            callback(response);
        } catch (err) {
            callback(false);
        }
    };
    export const GetProjects =
    async ({ callback,typeId  }) => {
        callback(false)
        try {

            let link = `${UrlApi.projects.get}`;
            const response = await GetFunction({
                data: {},
                Route: link,
                showalert: false,
                isMultipart: false,
            });
            callback(response);
        } catch (err) {
            callback(false);
        }
    };
    export const GetProjectsById =
    async ({ callback ,id }) => {
        callback(false)
        try {

            let link = `${UrlApi.projects.getbyid}/${id}`;
            const response = await GetFunction({
                data: {},
                Route: link,
                showalert: false,
                isMultipart: false,
            });
            callback(response);
        } catch (err) {
            callback(false);
        }
    };
    export const GetFeatures =
    async ({ callback }) => {
        callback(false)
        try {

            let link = `${UrlApi.projects.features}`;
            const response = await GetFunction({
                data: {},
                Route: link,
                showalert: false,
                isMultipart: false,
            });
            callback(response);
        } catch (err) {
            callback(false);
        }
    };
    export const GetServices =
    
    async ({callback}) => {

      try {
        let link = `${UrlApi.services.get}`;
        const response = await GetFunction({
          data: {},
          Route: link,
          showalert: false,
          isMultipart: false,
        });
        callback(response);
  
      } catch (err) {
        callback(false);
      }
    };
  

