import React from 'react';
import ClientsLogo from '../components/ClientsLogo';
import Form from '../components/Form';
import FormText from '../components/FormText';
import SectionGridLines from '../components/SectionGridLines';
import { GetServices } from './ProjectContainers/actionsP';
import { useState,useEffect } from 'react';
import { GetSettings } from './AboutContainers/actions';
const FormContainer = () => {
    const [data, setData] = useState([]);
  
  
    const GetData = () => {
        GetSettings({
            callback: (data) => {
             
              setData(data?.data);
            },
          })
    };
  
    useEffect(() => {
        window.scroll(0, 0)
      GetData();
    }, []);
    return (
        <section className="contact_us bg-dark-200">
            <div className="container">
                <div className="row justify-content-between">
                    
                   
                            {data && 
               <FormText
               key={data.id}
               item={data}
           />
            }
                    <div className="col-lg-6">
                        <Form></Form>
                    </div>
                </div>
                {/* <ClientsLogo></ClientsLogo> */}
            </div>
            <SectionGridLines></SectionGridLines>
        </section>
    );
};

export default FormContainer;